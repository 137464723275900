import { Navigation } from "./components/navigation";
import React from "react";
import {
  Routes,
  Route,
} from "react-router-dom";
import Home from "./Home";
import Device from './Device';
import Layout from './Layout';

const App = () => {
  return (
    <div>
      <Navigation />

      <Routes>
        <Route exact path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="device" element={<Device />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
