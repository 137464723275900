export const Footer = () => {
  return (
    <div id="footer">
       <div className='container center-block'>
          <p>
            <div className="row">
              <div className="col-md-1 col-sm-1 col-xs-1">
            <a href="https://s3-ap-northeast-1.amazonaws.com/diing.uploads/privacy-policy.html" target="_blank">Privacy</a>
            </div>
            <div className="col-md-2 col-md-offset-9 col-sm-offset-8 col-sm-3 col-xs-offset-5 col-xs-5">
            &copy; {new Date().getFullYear()} {' '}
            <a href='https://www.diing.com/' rel='nofollow noreferrer' target="_blank">
              Diing, Inc.
            </a>
            </div>
            </div>
          </p>
        </div>
    </div>
  )
}
