import React, { useState, useEffect } from "react";
import { useCardano } from '@cardano-foundation/cardano-connect-with-wallet';
import FlipCountdown from '@rumess/react-flip-countdown';
import moment from 'moment';
import Moment from 'react-moment';
import {QRCodeSVG} from 'qrcode.react';
import jwt from 'jwt-simple';
import { Circles } from  'react-loader-spinner';
import { isMobile } from 'react-device-detect';

Moment.globalFormat = 'YYYY-MM-DD hh-mm-ss';

function Device(props) {
  const [endAt, setEndAt] = useState("0");
  const [qrcodeContent, setQrcodeContent] = useState("0");
  const [isSpin, setIsSpin] = useState(false);
  const [token, setToken] = useState("0");
  const [svgSize, setSvgSize] = useState(360);

  const {
    isConnected,
    stakeAddress,
    connect
  } = useCardano();

  useEffect(() => {
    // Adjust QRcode size
    if (window.innerWidth < 340) {
      setSvgSize(280);
    } else if (window.innerWidth < 400) {
      setSvgSize(330);
    }

    let secret = atob(process.env.REACT_APP_PEM);

    if (endAt === "0") {
      setEndAt(moment().add(5,'m').format('YYYY-MM-DD HH:mm:ss').toString());
    }

    // Generate JWT
    if (qrcodeContent === "0" && stakeAddress) {
      let iat = parseInt(moment().format('X'));
      let exp = parseInt(iat) + 300;
      setIsSpin(false);

      let payload = {
        "aud": "536b79447265616d",  // "SkyDream" in hex display"
        "sub": stakeAddress,
        "iat": iat,
        "exp": exp,
        "iss": "https://skydream.skymon.io"
      };

      let token = jwt.encode(payload, secret, 'RS256');
      setQrcodeContent(token);
      setToken(token);
    }

    // Clean
    if (!isConnected) {
      setEndAt("0");
      setQrcodeContent("0");
      setIsSpin(false);
    }

    // Check stakeAddress
    if (stakeAddress === null && isConnected) {
      setIsSpin(true);
    }
  }, [endAt, qrcodeContent, stakeAddress, isConnected, connect, isSpin]);

  return (
    <div id="device">
      <div className="container">

      { isSpin ?
        <>
          <div className="row">
            <div className="col-xs-4 col-xs-offset-4">
              <Circles
                height="360"
                width="360"
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </div>
          <div className="device-text">
            <h2 className="text-center" style={{marginTop:20}}>Connecting...{isSpin}</h2>
          </div>
        </> :
        <>
          { isConnected ?
            <>
              <div className="row text-center">
                <div className="col-xs-12 col-md-4 col-md-offset-4">
                  {" "}
                  <QRCodeSVG value={qrcodeContent} size={svgSize} />
                </div>
              </div>
              <div className="row" style={{marginTop:30}}>

                <FlipCountdown
                  endAt={endAt}
                  endAtZero
                  hideYear
                  hideMonth
                  hideDay
                  hideHour
                />

              </div>

              { isMobile ?
                <div className="row" style={{marginTop:20}}>
                  <div className="col-sm-10 col-sm-offset-1 col-lg-6 col-lg-offset-3">
                    <a href="https://skydream-connect.skymon.io/" className="btn btn-lg btn-block btn-primary" role="button" link="https://skydream-connect.skymon.io/">Open Sky Dream</a>
                  </div>
                </div> :
                <></>
              }

            </> :
            <div className="col-xs-12 col-md-6 col-md-offset-3">
              <div className="device-text">
                <h2 className="text-center">Connect Wallet, Please.</h2>
              </div>
            </div>
          }
        </>
      }
      </div>
    </div>
  );
}

export default Device;
