import { useState, useEffect } from "react";
import { Header } from "./components/header";
import { About } from "./components/about";
// import { Services } from "./components/services";
// import { Gallery } from "./components/gallery";
import { Footer } from "./components/footer";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import appStoreBadge from './assets/app-store-badge.png';
import googlePlayBadge from './assets/google-play-badge.png';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <div className="container" style={{paddingBottom:40}}>
        <div className="row">
          <div className="col-xs-12 col-xs-offset-1 col-md-offset-3 col-md-6">
            <a
              href='https://bit.ly/3THBC5f'
              className='btn btn-lg page-scroll'
              target="_blank"
              rel="noreferrer"
            >
              <img alt="app-store-badge" src={appStoreBadge} width="200" />
            </a>{' '}
            <a
              href='https://bit.ly/3XbrOmN'
              className='btn btn-lg page-scroll'
              target="_blank"
              rel="noreferrer"
            >
              <img alt="google-play-badge" src={googlePlayBadge} width="220" />
            </a>{' '}
          </div>
        </div>
      </div>
      {/* <Services data={landingPageData.Services} /> */}
      {/* <Gallery data={landingPageData.Gallery}/> */}
      <Footer />
    </div>
  );
};

export default Home;
