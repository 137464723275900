import { useCardano, ConnectWalletButton } from '@cardano-foundation/cardano-connect-with-wallet';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import SkydreamLogo from '../assets/skydream-logo.png';
import { useMediaQuery } from 'react-responsive';

export const Navigation = (props) => {
  const { isConnected, installedExtensions } = useCardano();
  const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
  const logoML = ( (isMobile === true && isTablet !== true) ? -25 : 0 );
  const navigate = useNavigate();

  const navigateToDevice = () => {
    navigate('/device');
  };

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='align-middle row'>
        {/* logo */}
        <a className='navbar-brand page-scroll' href='/' style={{ zIndex: 9999, position: 'relative' }}>
          <img alt="Skydream-logo" src={SkydreamLogo} width="110" style={{marginTop: -16, marginLeft:logoML}} />
        </a>{' '}
        {/* connect wallet button */}
        { isMobile ?
          <ul className='nav navbar-nav pull-right'>
            <li>
              <ConnectWalletButton
                customActions={[{ label: 'Connect to Sky Dream', onClick: () => navigateToDevice() }]}
              />
            </li>
          </ul> :
          <ul className='nav navbar-nav pull-right'>
            <li>
              { (installedExtensions.length === 0) ?
                <ConnectWalletButton
                  label={`Unable to detect wallets`}
                /> :
                <ConnectWalletButton
                  customActions={[ {label: 'Connect to Sky Dream', onClick: () => navigateToDevice()} ]}
                />
              }
            </li>
          </ul>
        }
      </div>
    </nav>
  )
}
